// general routes
const Home = () =>
  import(/* webpackChunkName: "js/HomePage" */ '@pages/Home.vue');
const About = () =>
  import(/* webpackChunkName: "js/AboutPage" */ '@pages/About.vue');
const Terms = () =>
  import(/* webpackChunkName: "js/TermsPage" */ '@pages/Terms.vue');
const Dashboard = () =>
  import(/* webpackChunkName: "js/DashboardPage" */ '@pages/Dashboard.vue');
const Donation = () =>
  import(/* webpackChunkName: "js/DonationPage" */ '@pages/Donation.vue');
const Download = () =>
  import(/* webpackChunkName: "js/DownloadPage" */ '@pages/Download.vue');

const WalletBitgold = () =>
  import(/* webpackChunkName: "js/WalletBitgold" */ '@pages/WalletBitgold.vue');

const WalletUSDT = () =>
  import(/* webpackChunkName: "js/WalletUSDT" */ '@pages/WalletUSDT.vue');

const Preacher = () =>
  import(/* webpackChunkName: "js/PreacherPage" */ '@pages/Preacher.vue');

const Donate = () =>
  import(/* webpackChunkName: "js/DonatePage" */ '@pages/Donate.vue');

const Staking = () =>
  import(/* webpackChunkName: "js/StakingPage" */ '@pages/Staking.vue');

const DonateNow = () =>
  import(/* webpackChunkName: "js/DonateNowPage" */ '@pages/DonateNow.vue');

const Faq = () => import(/* webpackChunkName: "js/FaqPage" */ '@pages/Faq.vue');
const Airdrop = () =>
  import(/* webpackChunkName: "js/FaqPage" */ '@pages/Airdrop.vue');

const Mapping = () =>
  import(/* webpackChunkName: "js/MappingPage" */ '@pages/Mapping.vue');

const Mappings = () =>
  import(/* webpackChunkName: "js/MappingsPage" */ '@pages/Mappings.vue');

const Friends = () =>
  import(/* webpackChunkName: "js/FriendsPage" */ '@pages/Friends.vue');

const MappingDetail = () =>
  import(/* webpackChunkName: "js/MappingDetail" */ '@pages/MappingDetail.vue');

const DonationDetail = () =>
  import(
    /* webpackChunkName: "js/DonationDetail" */ '@pages/DonationDetail.vue'
  );

const Login = () =>
  import(/* webpackChunkName: "js/LoginPage" */ '@pages/Login.vue');

const LoginEmail = () =>
  import(/* webpackChunkName: "js/LoginEmailPage" */ '@pages/LoginEmail.vue');

const ForgotPassword = () =>
  import(
    /* webpackChunkName: "js/ForgotPasswordPage" */ '@pages/ForgotPassword.vue'
  );
const ResetPassword = () =>
  import(
    /* webpackChunkName: "js/ResetPasswordPage" */ '@pages/ResetPassword.vue'
  );
const Register = () =>
  import(/* webpackChunkName: "js/RegisterPage" */ '@pages/Register.vue');
const VerifyEmail = () =>
  import(/* webpackChunkName: "js/VerifyEmailPage" */ '@pages/VerifyEmail.vue');

const Settings = () =>
  import(
    /* webpackChunkName: "js/SettingsPage" */ '@pages/settings/Settings.vue'
  );
const Profile = () =>
  import(
    /* webpackChunkName: "js/ProfilePage" */ '@pages/settings/Profile.vue'
  );

const Password = () =>
  import(
    /* webpackChunkName: "js/PasswordPage" */ '@pages/settings/Password.vue'
  );
const Account = () =>
  import(
    /* webpackChunkName: "js/AccountPage" */ '@pages/settings/Account.vue'
  );
const NotFound = () =>
  import(/* webpackChunkName: "js/NotFoundPage" */ '@pages/NotFound.vue');

const AdminLayout = () =>
  import(
    /* webpackChunkName: "js/AdminLayoutPage" */ '@/layouts/AdminLayout.vue'
  );
const Admin = () =>
  import(/* webpackChunkName: "js/AdminPage" */ '@/views/admin/Admin.vue');
const Users = () =>
  import(/* webpackChunkName: "js/UsersPage" */ '@/views/admin/Users.vue');
const Roles = () =>
  import(/* webpackChunkName: "js/RolesPage" */ '@/views/admin/Roles.vue');
const Permissions = () =>
  import(
    /* webpackChunkName: "js/PermissionsPage" */ '@/views/admin/Permissions.vue'
  );
const AppSettings = () =>
  import(
    /* webpackChunkName: "js/AppSettingsPage" */ '@/views/admin/AppSettings.vue'
  );
// const PhpInfo = () =>
// import(/* webpackChunkName: "js/PhpInfoPage" */ '@/views/admin/PhpInfo.vue');
import { defineAsyncComponent } from 'vue';

import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import roleAdmin from '@/middleware/roleAdmin';
import roleSuperAdmin from '@/middleware/roleSuperAdmin';
import roleUser from '@/middleware/roleUser';

export default [
  {
    path: '/',
    // component: () => Home,
    component: Home,
    name: 'home',
  },
  {
    path: '/about',
    component: About,
    name: 'about',
  },
  {
    path: '/terms',
    component: Terms,
    name: 'terms',
  },
  {
    path: '/download',
    component: Download,
    name: 'download',
  },
  {
    path: '/faq',
    component: Faq,
    name: 'faq',
  },
  {
    path: '/airdrop',
    component: Airdrop,
    name: 'airdrop',
  },
  {
    path: '/staking',
    component: Staking,
    name: 'staking',
  },

  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/donation',
    component: Donation,
    name: 'donation',
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/mapping',
    component: Mapping,
    name: 'mapping',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/mappings',
    component: Mappings,
    name: 'mappings',
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/my/mapping/detail',
    component: MappingDetail,
    name: 'mappingDetail',
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/preacher',
    component: Preacher,
    name: 'preacher',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/friends',
    component: Friends,
    name: 'friends',
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/wallet/bitgold',
    component: WalletBitgold,
    name: 'WalletBitgold',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/wallet/usdt',
    component: WalletUSDT,
    name: 'WalletUSDT',
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/donate',
    component: Donate,
    name: 'donate',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/donateNow',
    component: DonateNow,
    name: 'donateNow',
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/my/donation/detail',
    component: DonationDetail,
    name: 'donationDetail',
    meta: {
      middleware: [auth],
    },
  },

  {
    path: '/admin',
    component: AdminLayout,
    children: [
      {
        path: '',
        name: 'admin',
        component: Admin,
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
      },
      {
        path: 'roles',
        name: 'roles',
        component: Roles,
      },
      {
        path: 'permissions',
        name: 'permissions',
        component: Permissions,
      },
      {
        path: 'phpinfo',
        name: 'phpinfo',
        component: defineAsyncComponent(
          () => import('@/views/admin/PhpInfo.vue'),
        ),
      },
      {
        path: 'app-settings',
        name: 'app-settings',
        component: AppSettings,
      },
    ],
    meta: {
      middleware: [roleAdmin],
    },
  },
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      middleware: [guest],
    },
  },

  {
    path: '/loginEmail',
    component: LoginEmail,
    name: 'LoginEmail',
    meta: {
      middleware: [guest],
    },
  },

  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot-password',
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/reset-password/:token',
    props: (route) => ({
      token: route.params.token,
      email: route.query.email,
    }),
    component: ResetPassword,
    name: 'reset-password',
    meta: {
      middleware: [guest],
    },
  },
  // {
  //   path: '/register',
  //   component: Register,
  //   name: 'register',
  //   meta: {
  //     middleware: [guest],
  //   },
  // },
  {
    path: '/verify-email/:id/:hash',
    props: (route) => ({
      id: route.params.id,
      hash: route.params.hash,
    }),
    component: VerifyEmail,
    name: 'verify-email',
  },
  {
    path: '/settings',
    component: Settings,
    redirect: {
      name: 'account',
    },
    name: 'settings',
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: 'account',
        component: Account,
        name: 'account',
        meta: {
          middleware: [auth],
        },
      },

      {
        path: 'profile',
        component: Profile,
        name: 'profile',
        meta: {
          middleware: [auth],
        },
      },
      {
        path: 'password',
        component: Password,
        name: 'password',
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    // path: '/:pathMatch(.*)*',
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];
